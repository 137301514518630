<template>
  <div :class="['map-crop-card', cropTitle ? 'active' : '']" v-show="!showId">
    <div class="crop-title">
      <span>{{ cropTitle ? "水稻种植" : "凌家滩水稻种植" }}</span>
      <img
        class="card-switch"
        src="@/assets/image/card_switch.png"
        alt=""
        @click="switchType"
      />
    </div>
    <div class="flip-card-inner">
      <div class="crop-intro flip-card-back">
        <div class="intro-all">
          <p class="num">47843.9</p>
          <p class="tips">总面积(亩)</p>
        </div>
        <div class="intro-list">
          <div class="intro-crop-item">
            <p class="item-title one-txt-cut">华浙优261</p>
            <p class="crop-info">
              <span class="number">23605.2</span><span>亩</span>
            </p>
          </div>
          <div class="intro-crop-item">
            <p class="item-title one-txt-cut">南粳46</p>
            <p class="crop-info">
              <span class="number">7608.7</span><span>亩</span>
            </p>
          </div>
          <div class="intro-crop-item">
            <p class="item-title one-txt-cut">农两优998</p>
            <p class="crop-info">
              <span class="number">8272</span><span>亩</span>
            </p>
          </div>
          <div class="intro-crop-item">
            <p class="item-title one-txt-cut">珠两优5298</p>
            <p class="crop-info">
              <span class="number">8358</span><span>亩</span>
            </p>
          </div>
        </div>
      </div>
      <div class="crop-intro flip-card-front">
        <div class="intro-all">
          <p class="num">18176</p>
          <p class="tips">总面积(亩)</p>
        </div>
        <div class="intro-list">
          <div class="intro-crop-item">
            <p class="item-title one-txt-cut">华浙优261</p>
            <p class="crop-info">
              <span class="number">2450</span><span>亩</span>
            </p>
          </div>
          <div class="intro-crop-item">
            <p class="item-title one-txt-cut">南粳46</p>
            <p class="crop-info">
              <span class="number">3937</span><span>亩</span>
            </p>
          </div>
          <div class="intro-crop-item">
            <p class="item-title one-txt-cut">农两优</p>
            <p class="crop-info">
              <span class="number">5510</span><span>亩</span>
            </p>
          </div>
          <div class="intro-crop-item">
            <p class="item-title one-txt-cut">珠两优5298</p>
            <p class="crop-info">
              <span class="number">6279</span><span>亩</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cropTitle: true,
    };
  },

  methods: {
    switchType() {
      this.cropTitle = !this.cropTitle;
    },
  },
};
</script>

<style lang="less" scoped>
.map-crop-card {
  height: 408px;
  width: 282px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: rgba(15, 36, 61, 0.8);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 470px;
  left: 78px;
  z-index: 1999;
  perspective: 2200px;
  &.active .flip-card-inner {
    transform: rotateY(180deg);
  }
  .crop-title {
    width: 100%;
    height: 32px;
    font-size: 16px;
    color: #fff;
    background-color: rgba(21, 92, 165, 0.9);
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 19px;
      cursor: pointer;
    }
  }
  .flip-card-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
    .crop-intro {
      width: 100%;
      height: calc(100% - 30px);
      position: absolute;

      backface-visibility: hidden;
      padding: 6px 12px;
      font-size: 14px;
      color: #fff;
      line-height: 24px;
      &.flip-card-front {
        transform: rotateY(0deg);
        z-index: 2;
      }
      &.flip-card-back {
        transform: rotateY(180deg);
      }
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3e90e5;
      }
      .intro-all {
        width: 147px;
        height: 147px;
        background: url("../assets/image/sd_card_yuan.png") center center
          no-repeat;
        background-size: cover;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        p {
          color: #fff;
        }
        .num {
          font-size: 24px;
          font-weight: bold;
          line-height: 21px;
        }
        .tips {
          font-size: 14px;
        }
      }
      .intro-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        .intro-crop-item {
          width: 100%;
          height: 40px;
          background: url("../assets/image/sd_card_row.png") center center
            no-repeat;
          background-size: contain;
          margin-bottom: 14px;
          display: flex;
          align-items: center;
          padding: 0 21px 0 30px;
          justify-content: space-between;
          &:last-of-type {
            margin-bottom: 0;
          }
          .item-title {
            font-size: 14px;
            width: 50%;
          }
          .crop-info {
            display: flex;
            span {
              font-size: 14px;

              &.number {
                font-size: 20px;
                font-weight: bold;
                background: linear-gradient(0deg, #3e90e5 0%, #3eb8e5 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
